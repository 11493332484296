export const environment = {
  production: false,
  apigwUrl: 'https://api-dev.sc.geappl.io/eola',
  apiGateway: {
    url: "https://api-dev.sc.geappl.io/",
    stage: "eola"
  },  
  AllowScreenAuth: true,  
  cognitoOptions: {
    ClientId: '68h4jq5b3oi4qq02690p6ve3k2', // Your client id here
    AppWebDomain: 'mesauth.auth.us-east-1.amazoncognito.com', // Exclude the "https://" part.
    TokenScopesArray: ['openid', 'profile'],
    UserPoolId: 'us-east-1_s1pKZcsUG'
  }
};